<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <strong>รายการหวย</strong>
      <button class="btn btn-sm btn-link" @click="addMarket">เพิ่มหวย</button>
    </div>
    <div class="card">
      <table class="table table-group table-sm table-hover mb-0">
        <thead>
          <tr class="text-info">
            <th width="60%">รายการ</th>
            <th width="10%">ลำดับ</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="(group, groupKey) in groupBy" :key="groupKey">
          <tr>
            <th colspan="3">{{ group.item_name }}</th>
          </tr>
          <tr v-for="(item, itemKey) in group.items" :key="itemKey">
            <td style="vertical-align: middle;">{{ item.item_name }}</td>
            <td>{{ item.sort || '0' }}</td>
            <td class="text-right">
              <button class="btn btn-sm btn-link" @click="editMarket(item)">แก้ไข</button>
            </td>
          </tr>
          <tr v-if="items.length == 0">
            <td colspan="3" class="small text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>

    <MarketModal :data="data" :groups="groupItems" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import _ from 'lodash'

import TransactionService from '@/services/TransactionService.js'
import MarketModal from './components/MarketModal'

const defaultData = {
  _id: null,
  item_type: 'Market',
  item_name: '',
  owners: {
    mGroupId: null
  },
  sort: 0
}

export default {
  name: 'AdminSettingMarket',
  components: {
    MarketModal
  },
  data() {
    return {
      isShowModal: false,
      data: JSON.parse(JSON.stringify(defaultData)),
      Groups: [],
      Markets: []
    }
  },
  computed: {
    groupItems() {
      return this.Groups
    },
    items() {
      return this.Markets
    },
    groupBy() {
      const groupItems = _.groupBy(this.items, 'owners.mGroupId')
      return this.groupItems.map((group)=>{
        return {
          ...group,
          items: groupItems[group._id]
        }
      })
      .filter((group)=>{
        return group?.items
      })
    }
  },
  methods: {
    loadGroup() {
      TransactionService.gets('MarketGroup').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Groups = response.data
        }
      })
    },
    loadMarket() {
      TransactionService.gets('Market').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Markets = response.data
        }
      })
    },
    addMarket() {
      this.data = JSON.parse(JSON.stringify(defaultData))
      this.isShowModal = true
    },
    editMarket(item) {
      console.log(item)
      let data = JSON.parse(JSON.stringify(defaultData))
      this.data = {
        ...data,
        _id: item._id,
        item_type: item.item_type,
        item_name: item.item_name,
        owners: {
          ...data.owners,
          ...item.owners
        },
        sort: item.sort
      }
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      console.log('needReload', needReload)
      if(needReload) {
        this.loadMarket()
      }
    },
  },
  mounted() {
    this.loadGroup()
    this.loadMarket()
  }
}
</script>
