<template>
  <b-modal
    id="market-madal"
    ref="market-madal"
    hide-footer
    title="ตั้งค่าหวย"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="กลุ่มหวย"
      >
        <b-form-select v-model="mGroupId" :options="groupOptions"></b-form-select>
      </b-form-group>

      <b-form-group
        label="ชื่อหวย"
      >
        <b-form-input
          v-model="input.item_name"
          type="text"
          placeholder="ชื่อหวย"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ลำดับ"
      >
        <b-form-input
          v-model="input.sort"
          type="number"
          placeholder="ลำดับการแสดงผล"
          required
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import TransactionService from '@/services/TransactionService.js'
import Swal from 'sweetalert2'

export default {
  name: 'MarketModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    groups: {
      type: Array,
      default: []
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      input: {},
      mGroupId: null,
      isProcess: false,
      needReload: false
    }
  },
  computed: {
    groupOptions() {
      let options = [{
        value: null,
        text: '=== เลือกกลุ่ม ==='
      }]
      for(const item of this.groups) {
        options.push({
          value: item._id,
          text: item.item_name
        })
      }

      return options
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
      this.mGroupId = this.input.owners?.mGroupId
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
    },
    showModal() {
      this.$refs['market-madal'].show()
    },
    hideModal() {
      this.$refs['market-madal'].hide()
    },
    handleSubmit() {
      console.log(JSON.stringify(this.input))
      this.input.owners.mGroupId = this.mGroupId
      if(this.input?._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      TransactionService.save(this.input)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    update() {
      TransactionService.update(this.input._id, this.input)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#market-madal {
  input[type=text] {
    color: blue;
  }
}
</style>
